import React, { useState, useCallback, useEffect } from 'react';
import {
    Button,
    CardComponent,
    AlertComponent,
    InputPasswordComponent,
    InputComponent,
} from '../../../components/shared';

const Login = ({
    formMode = '',
    title = '',
    errorMessage = '',
    passwordErrMsg = '',
    username = '',
    clickGate = '',
    onUserNameChange = () => { },
    password = '',
    onChangePassword = () => { },
    confirmPassword = '',
    onChangeConfirmPassword = () => { },
    onForgotPassword = () => { },
    onSubmit = () => { },
}) => {
    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                onSubmit();
            }
        },
        [onSubmit]
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const [passwordVisible, setPasswordVisible] = useState(false); // shows the password field when 'eye' icon is clicked
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false); // shows the confirm password field when 'eye' icon is clicked

    return (
        <CardComponent title={title} className='login-card'>
            {errorMessage && <AlertComponent className='alertText' message={errorMessage} type='error' />}
            {passwordErrMsg && <AlertComponent className='alertText' message={passwordErrMsg} type='error' />}
            <div className='fieldView'>
                <label className='labelView emaillable'>Email</label>
                <InputComponent
                    value={username}
                    placeholder={'Email'}
                    onChangeEvent={(e) => {
                        onUserNameChange(e.target.value);
                    }}
                    disabled={clickGate}
                />
            </div>
            <div className='fieldView'>
                <label className='labelView'>{formMode === 'LOGIN' ? 'Password' : 'New Password'}</label>
                <InputPasswordComponent
                    value={password}
                    placeholder={'Password'}
                    onChange={(e) => onChangePassword(e?.target?.value)}
                    disabled={clickGate}
                    className='password'
                    visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                />
            </div>
            {formMode === 'CREATE_NEW_PASSWORD' ? (
                <div className='fieldView'>
                    <label className='labelView'>{'Confirm Password'}</label>
                    <InputPasswordComponent
                        className='confirmPassword'
                        value={confirmPassword}
                        placeholder={'Confirm Password'}
                        onChange={(e) => onChangeConfirmPassword(e?.target?.value)}
                        visibilityToggle={{
                            visible: confirmPasswordVisible,
                            onVisibleChange: setConfirmPasswordVisible,
                        }}
                    />
                </div>
            ) : null}
            <div>
                {formMode === 'LOGIN' && (
                    <div className='signBtnView'>
                        <Button type='link' onClickEvent={onForgotPassword} className='forgetPassword'>
                            Forgot Password
                        </Button>
                    </div>
                )}
                <div className='signBtnView'>
                    <Button
                        type='primary'
                        onClickEvent={onSubmit}
                        onKeyPress={handleKeyDown}
                        disabled={clickGate}
                        className='SignBtn'
                    >
                        Login
                    </Button>
                </div>
            </div>
        </CardComponent>
    );
};

export default Login;