import React, { useContext, useMemo } from "react";
import { useSelector } from 'react-redux';
import { Sider, Menu } from "../../components/shared";

import "./navbar.scss";
import { Liveline, LivelineMobile } from "../../assets/dark-theme";

import {
    LineChartOutlined,
    MenuUnfoldOutlined,
    PieChartOutlined,
    WarningOutlined,
} from "@ant-design/icons";
import {
    users,
    Signal_Data_New_1,
    VML_1,
    admin,
    cells,
    controls,
    dashboard,
    equipment,
    facilities,
    historicalRunsIcon,
    liveHistoricalData,
    plug,
    products,
    rulesAndConfiguration,
    matrix,
    Alert,
    facilitiesGreyIcon,
    AdvancedController,
} from "../../assets/dark-theme";
import { AbilityContext } from "../../components/shared/Can";
import { menuItemIsDisabled } from "../../components/shared/menu/MenuComponent";

const facilityListSelector = {
    label: "Select Facility",
    key: "facility_selector",
    title: "Select Facility",
    icon: (
        <img
            src={facilitiesGreyIcon}
            alt="Facility List"
            className="sideMenuLogo"
        />
    ),
};

export const navMenuItems = [
    {
        label: "Dashboard",
        key: "dashboard",
        title: "Dashboard",
        icon: <img src={dashboard} alt="Dashboard" className="sideMenuLogo" />,
    },
    // {
    //     label: "Cell Dashboard",
    //     key: "cell-dashboard",
    //     title: "Cell Dashboard",
    //     icon: (
    //         <PieChartOutlined
    //             className="sideMenuLogo"
    //             style={{ fontSize: "30px", color: "#c9c9c9" }}
    //         />
    //     ),
    // },
    // {
    //     label: "Operator",
    //     key: "operator",
    //     title: "Operator",
    //     icon: (
    //         <MenuUnfoldOutlined
    //             className="sideMenuLogo"
    //             style={{ fontSize: "30px", color: "#c9c9c9" }}
    //         />
    //     ),
    // },
    {
        label: "Analytics",
        key: "oee-console",
        title: "Analytics",
        icon: (
            <LineChartOutlined
                className="sideMenuLogo"
                style={{ fontSize: "30px", color: "#c9c9c9" }}
            />
        ),
    },
    // {
    //     label: "Model Metrics",
    //     key: "model-metrics",
    //     title: "Model Metrics",
    //     icon: (
    //         <CodeOutlined
    //             className="sideMenuLogo"
    //             style={{ fontSize: "30px", color: "#c9c9c9" }}
    //         />
    //     ),
    // },
    // {
    //     label: "Alert Log",
    //     key: "alert-log",
    //     title: "Alert Log",
    //     icon: (
    //         <WarningOutlined
    //             className="sideMenuLogo"
    //             style={{ fontSize: "30px", color: "#c9c9c9" }}
    //         />
    //     ),
    // },
    {
        label: "Admin",
        key: "general_admin",
        title: "Admin",
        icon: <img src={admin} alt="Admin" className="sideMenuLogo" />,
        children: [
            {
                label: "Facilities",
                key: "general_admin/facilities",
                title: "Facilities",
                icon: (
                    <img
                        src={facilities}
                        alt="Facilities"
                        className="sideMenuLogo"
                    />
                ),
            },
            {
                label: "Cells",
                key: "general_admin/cells",
                title: "Cells",
                icon: <img src={cells} alt="Cells" className="sideMenuLogo" />,
            },
            {
                label: "Equipment",
                key: "general_admin/equipment",
                title: "Equipment",
                icon: (
                    <img
                        src={equipment}
                        alt="equipment"
                        className="sideMenuLogo"
                    />
                ),
            },
            {
                label: "Products",
                key: "general_admin/products",
                title: "Products",
                icon: (
                    <img
                        src={products}
                        alt="products"
                        className="sideMenuLogo"
                    />
                ),
            },
            {
                label: "Users",
                key: "general_admin/users",
                title: "Users",
                icon: (
                    <img
                        src={users}
                        alt="users"
                        className="sideMenuLogo"
                        height={26}
                    />
                ),
            },
        ],
    },
    {
        label: "Alert Log",
        key: "alert-log",
        title: "Alert Log",
        icon: (
            <WarningOutlined
                className="sideMenuLogo"
                style={{ fontSize: "30px", color: "#c9c9c9" }}
            />
        ),
    },
    {
        label: "Monitor",
        key: "live_historical_data",
        title: "Monitor",
        icon: (
            <img
                src={liveHistoricalData}
                alt="liveHistoricalData"
                className="sideMenuLogo"
            />
        ),
        children: [
            {
                label: "Signal Data",
                key: "live_historical_data/monitor_live_data",
                title: "Signal Data",
                icon: (
                    <img
                        src={Signal_Data_New_1}
                        alt="monitorSignalData"
                        className="sideMenuLogo"
                    />
                ),
            },
            // {
            //     label: 'Rules Control',
            //     key: 'live_historical_data/monitor_rules',
            //     title: 'Rules Control',
            //     icon: <img src={monitorRules} alt='monitorRules' className='sideMenuLogo' />,
            // },
            // {
            //     label: 'ML Control',
            //     key: 'live_historical_data/monitor_machine_learning',
            //     title: 'ML Control',
            //     icon: <img src={ML_Control_1} alt='monitorMlControl' className='sideMenuLogo' />,
            // },
            {
                label: "Live Services",
                key: "live_historical_data/live_services",
                title: "Live Services",
                icon: (
                    <img
                        src={plug}
                        alt="liveServices"
                        className="sideMenuLogo"
                    />
                ),
            },
            {
                label: "Historical Runs",
                key: "live_historical_data/historical_runs",
                title: "Historical Runs",
                icon: (
                    <img
                        src={historicalRunsIcon}
                        alt="historicalRuns"
                        className="sideMenuLogo"
                    />
                ),
            },
            {
                label: "Advanced Controllers",
                key: "live_historical_data/advanced_controllers",
                title: "Advanced Controllers",
                icon: (
                    <img
                        src={AdvancedController}
                        alt="advancedController"
                        className="sideMenuLogo"
                    />
                ),
            },
        ],
    },
    // {
    //     label: 'Reporting',
    //     key: 'reporting',
    //     title: 'Reporting',
    //     icon: <img src={reporting} alt='reporting' className='sideMenuLogo' />,
    //     children: [
    //         {
    //             label: 'Run Reports',
    //             title: 'Run Reports',
    //             key: 'reporting/graphs_charts',
    //             icon: <img src={graphsAndCharts} alt='graphsAndCharts' className='sidemenulogo' />,
    //         },
    //         {
    //             label: 'Impact KPI',
    //             key: 'reporting/impact_kpi',
    //             title: 'Impact KPI',
    //             icon: <img src={impactKPI} alt='impactKPI' className='sideMenuLogo' />,
    //         },
    //     ],
    // },
    {
        label: "Controls",
        key: "controls_admin",
        title: "Controls",
        icon: <img src={controls} alt="controls" className="sideMenuLogo" />,
        children: [
            {
                label: "Rules",
                key: "controls_admin/rules_configuration",
                title: "Rules Configuration",
                icon: (
                    <img
                        src={rulesAndConfiguration}
                        alt="rulesAndConfiguration"
                        className="sideMenuLogo"
                    />
                ),
            },
            {
                label: "Alerts",
                key: "controls_admin/alerts_configuration",
                title: "Alerts Configuration",
                icon: (
                    <img
                        src={Alert}
                        alt="alertsAndConfiguration"
                        className="sideMenuLogo"
                    />
                ),
            },
            {
                label: "ML",
                key: "controls_admin/ml_configuration",
                title: "ML Configuration",
                icon: (
                    <img src={VML_1} alt="MLConfig" className="sideMenuLogo" />
                ),
            },
            {
                label: "State Action Matrix",
                key: "controls_admin/state_action_matrix",
                title: "State Action Matrix",
                icon: (
                    <img
                        src={matrix}
                        alt="rulesAndConfiguration"
                        className="sideMenuLogo"
                    />
                ),
            },
        ],
    },
    // {
    //     label: 'Machine Learning',
    //     key: 'ml_admin',
    //     title: 'Machine Learning',
    //     icon: <img src={MLAdmin} alt='MLAdmin' className='sideMenuLogo' />,
    //     children: [
    //         {
    //             label: 'DPK',
    //             key: 'ml_admin/dpk',
    //             title: 'DPK',
    //             icon: <img src={DPK} alt='DPK' className='sideMenuLogo' />,
    //         },
    //         {
    //             label: 'LPP',
    //             key: 'ml_admin/lpp',
    //             title: 'LPP',
    //             icon: <img src={LPP} alt='LPP' className='sideMenuLogo' />,
    //         },
    //         {
    //             label: 'LCP',
    //             key: 'ml_admin/lcp_trained',
    //             title: 'LCP',
    //             icon: <img src={LCPTrained} alt='LCPTrained' className='sideMenuLogo' />,
    //         },
    //         // {
    //         //     label: 'Back Test',
    //         //     key: 'ml_admin/backtest',
    //         //     title: 'Back Test',
    //         //     icon: <img src={backTest} alt='backTest' className='sideMenuLogo' />,
    //         // },
    //         // {
    //         //     label: 'Ghost Scheduler',
    //         //     key: 'ml_admin/ghost_scheduler',
    //         //     title: 'Ghost Scheduler',
    //         //     icon: <img src={ghostScheduler} alt='ghostScheduler' className='sideMenuLogo' />,
    //         // },
    //         {
    //             label: 'LCP Performance',
    //             key: 'ml_admin/lcp_performance',
    //             title: 'LCP Performance',
    //             icon: <img src={lcpPerformance} alt='lcpPerformance' className='sidemenulogo' />,
    //         },
    //     ],
    // },
    // {
    //     label: 'Predictive Alarms',
    //     key: 'predictive_alarms_admin',
    //     title: 'Predictive Alarms',
    //     icon: <img src={predictiveAlarmsAdmin} alt='predictiveAlarms' className='sideMenuLogo' />,
    //     children: [
    //         {
    //             label: 'Monitor',
    //             key: 'predictive_alarms_admin/monitor',
    //             title: 'Monitor',
    //             icon: <img src={predictiveAlarmsMonitor} alt='predictiveAlarmsMonitor' className='sideMenuLogo' />,
    //         },
    //     ],
    // },
    // {
    //     label: 'Monitor Stack',
    //     key: 'monitor_stack',
    //     title: 'Monitor Stack',
    //     icon: <img src={monitorStack} alt='monitorStack' className='sideMenuLogo' />,
    // },
];

const LeftNav = ({
    onExpandMenu = () => {},
    openKeys,
    visibleSideMenu,
    mobileCollapse,
    mobile,
    onCollapseMenu = () => {},
    selectedKey,
    closeMenu = () => {},
}) => {
    const ability = useContext(AbilityContext);
    const userSelection = useSelector((x) => x?.userSelection);
    const facilityList = useSelector((x) => x?.facilityList);
    const facilityListMenu = [...facilityList?.data]?.sort((fA) =>
        userSelection
            ? userSelection.facility?.livelineId === fA.facilityId
                ? -1
                : 1
            : 1
    );
    // sorting facility menu to keep the current selection at the top
    // css manipulates facility sub menu to keep first option non reactive (navbar.scss line 252)

    const facilitySelectorMenuItem = useMemo(
        () => ({
            ...facilityListSelector,
            label: userSelection?.facility.text || facilityListSelector.label,
            children:
                facilityListMenu?.length > 1
                    ? facilityListMenu.map((facility) => ({
                          label: facility.text,
                          key: `facility_selector/${facility.facilityId}`,
                          title: `Select ${facility.text}`,
                      }))
                    : undefined,
        }),
        [facilityListMenu, userSelection?.facility]
    );

    const accessibleMenuItems = useMemo(
        () =>
            navMenuItems.reduce(
                (prevMenuItems, currMenuItem) => {
                    if (currMenuItem?.children?.length) {
                        const childItems = currMenuItem.children.filter(
                            ({ key }) =>
                                !menuItemIsDisabled(
                                    key,
                                    ability,
                                    userSelection.facility?.livelineId
                                )
                        );

                        const retVal = [...prevMenuItems];

                        if (childItems.length) {
                            retVal.push({
                                ...currMenuItem,
                                children: childItems,
                            });
                        }

                        return retVal;
                    }

                    const retVal = [...prevMenuItems];

                    if (
                        !menuItemIsDisabled(
                            currMenuItem.key,
                            ability,
                            userSelection.facility?.livelineId
                        )
                    ) {
                        retVal.push(currMenuItem);
                    }

                    return retVal;
                },
                [facilitySelectorMenuItem]
            ),
        [ability, userSelection, facilitySelectorMenuItem]
    );

    return (
        <div
            className={`side-menu ${
                visibleSideMenu ? "alignLeftNavCollapse" : "alignLeftNav"
            } ${mobileCollapse ? "mobileCollpase" : ""}`}
        >
            <Sider
                width={"15vw"}
                breakpoint="lg"
                collapsible
                collapsed={visibleSideMenu}
                onCollapse={onCollapseMenu}
            >
                {mobile ? (
                    <a href="/">
                        <img
                            src={LivelineMobile}
                            className="logo"
                            alt="livelinelog"
                        />
                    </a>
                ) : (
                    <a href="/">
                        {visibleSideMenu ? (
                            <img
                                src={LivelineMobile}
                                className="logo"
                                alt="livelinelog"
                            />
                        ) : (
                            <img
                                src={Liveline}
                                className="logo"
                                alt="livelinelog"
                            />
                        )}
                    </a>
                )}

                <Menu
                    style={{
                        maxHeight: "calc(100vh - 117px)",
                        overflow: "auto",
                    }}
                    items={accessibleMenuItems}
                    openKeys={openKeys}
                    onOpenChange={onExpandMenu}
                    defaultSelectedKeys={[selectedKey]}
                    closeMenu={closeMenu}
                />
            </Sider>
        </div>
    );
};

export default LeftNav;
