import { createSlice } from '@reduxjs/toolkit';

const name = 'plots';

function createInitialState() {
    return JSON.parse(localStorage.getItem(name)) || {};
}

function createReducers() {
    return {
        updatePlotsByCell,
    };

    function updatePlotsByCell(state, action) {
        const {
            payload: {
                cellId,
                plots
            }
        } = action;

        state[cellId] = plots;
        localStorage.setItem(name, JSON.stringify(state, null, 2));
    }
}

const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const plotActions = slice.actions;
export const plotReducer = slice.reducer;
