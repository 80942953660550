import React, { lazy } from 'react';

const Monitor = lazy(() => import('./index'));
const SignalData = lazy(() => import('./signal-data'));
const HistoricalRuns = lazy(() => import('./historical-runs'));
const LiveServices = lazy(() => import('./live-services'));
const AdvancedControllers = lazy(() => import('./advanced-controller'));

const MonitorRoute = () => {
    return [
        {
            key: 'monitor_live_data',
            path: '/live_historical_data/monitor_live_data',
            component: <SignalData />,
        },
        {
            key: 'monitormodels',
            path: '/live_historical_data/monitormodels',
            component: <Monitor />,
        },
        {
            key: 'historical_runs',
            path: '/live_historical_data/historical_runs',
            component: <HistoricalRuns />,
        },
        {
            key: 'live_services',
            path: '/live_historical_data/live_services',
            component: <LiveServices />,
        },
        {
            key: 'advanced_controller',
            path: '/live_historical_data/advanced_controllers',
            component: <AdvancedControllers />,
        },
    ];
};

export default MonitorRoute;
