import React, { useState, useCallback, useEffect } from 'react';
import { Button, CardComponent } from '../../../components/shared';
import { Alert, Input } from 'antd';
import { authActions } from '../../../state/features/authReducer';
import { useDispatch, useSelector } from 'react-redux';

export const ForgotPassword = ({ onComplete }) => {
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [validationError, setValidationError] = useState('');
    const [isConfirming, setIsConfirming] = useState(false);
    const errorMessage = useSelector((x) => x.auth.error);
    const authLoading = useSelector((x) => x?.auth?.loading);
    const dispatch = useDispatch();

    const onSubmit = useCallback(async () => {
        if (username.length > 1) {
            if (!isConfirming) {
                onSubmitVerificationCode();
            } else if (password.length > 1 && confirmationCode.length > 1) {
                onRecoverPassword();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, password, confirmationCode]);


    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter') {
          onSubmit();
        }
    }, [onSubmit])

    useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
        document.removeEventListener('keydown', handleKeyDown);
    };
    }, [handleKeyDown]);
    
    const clickGate = Boolean(authLoading);

    async function onSubmitVerificationCode() {
        dispatch(authActions.forgotPassword({ username }));
        setIsConfirming(true)
    }

    async function onRecoverPassword() {
        dispatch(authActions.recoverPassword({ username, password, code: confirmationCode.trim() }));
        onComplete();
    }

    const toDisplayString = () => {
        return 'Reset Password';
    };

    const title = toDisplayString();
    return (
        <CardComponent title={title} className='login-card'>
           {errorMessage && <Alert className='alertText' message={errorMessage} type='error' />}
            <div className='fieldView'>
                <label className='labelView emaillable'>Email</label>
                <Input
                    value={username}
                    placeholder={'Email'}
                    onChange={(e) => {
                        setUserName(e.target.value);
                    }}
                    disabled={clickGate}
                />
            </div>
            {isConfirming && (
            <>
                <div className='fieldView'>
                    <label className='labelView'>New Password</label>
                    <Input.Password
                        value={password}
                        placeholder={'Password'}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={clickGate}
                    />
                </div>
                <div style={{ color: 'white' }}>
                    <label className='labelView emaillable'>Verification Code</label>
                    <Input
                        value={confirmationCode}
                        onChange={(e) => {
                            setConfirmationCode(e.target.value);
                        }}
                        disabled={clickGate}
                    />
                </div>
            </>

            )}
            
            {!isConfirming ? (
            <div style={{ marginTop: '15px' }}>
                <Button type='primary' onClickEvent={onSubmit}>
                    Send Code
                </Button>
                
            </div>
            ) :
            (
                <div className='resetBtn'>
                    <Button type='primary' onClickEvent={onSubmit}>
                        Reset Password
                    </Button>
                </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type='link' onClickEvent={() => onComplete()} className='forgetPassword'>
                Cancel
            </Button>
            </div>
            
        </CardComponent>
    );
};
